import React, { useEffect } from 'react'

import './ManagerAccessNotificator.css'

import { notificationTypeStyles } from './consts'

const ManagerAccessNotification = ({
   notification,
   onClose
}) => {
   const { id, data, type } = notification
   const typeStyle = notificationTypeStyles[type]
   const { header, text } = data

   const handleClose = () => onClose(id)

   useEffect(() => {
      const timoutId = setTimeout(handleClose, 5000)

      return () => clearTimeout(timoutId)
   }, [])

   return (
      <div className={`manager-access-notification ${typeStyle}`}>
         <div className='manager-access-notification__body'>
            <div className='manager-access-notification__header'>
               <img className='manager-access-notification__icon' src={`/assets/notifications/notification-icon-${type}.svg`} alt='' />
               <h4 className='manager-access-notification__title'>
                  {header}
               </h4>
               <button 
                  className='manager-access-notification__close-btn'
                  onClick={handleClose}
               >
                  <img src='/assets/notifications/close-icon.svg' alt='' />
               </button>
            </div>
            <p className='manager-access-notification__text'>
               {text}
            </p>
         </div>
      </div>
   )
}

export default ManagerAccessNotification