import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   managerAccessNotifications: []
}

const notificationsSlice = createSlice({
   name: 'notifications',
   initialState,
   reducers: {
      addManagerAccessNotification: (state, action) => {         
         state.managerAccessNotifications = (
            state.managerAccessNotifications.find(notification => notification.text === action.payload.data.text)
               ? state.managerAccessNotifications
               : [...state.managerAccessNotifications, action.payload]
         )
      },
      deleteManagerAccessNotification: (state, action) => {
         state.managerAccessNotifications = state.managerAccessNotifications.filter(notification => notification.id !== action.payload)
      }
   }
})

export const {
   addManagerAccessNotification,
   deleteManagerAccessNotification
} = notificationsSlice.actions
export default notificationsSlice.reducer