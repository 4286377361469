import { createSlice } from "@reduxjs/toolkit"
import { DEFAULT_LIMIT } from "../../../utils/consts/defaultContactsParams"

const initialState = {
   limit: DEFAULT_LIMIT,
   cursor: null,
   searchValue: '',
   channel: null
}

const contactsFilterSlice = createSlice({
   name: 'contactsFilter',
   initialState,
   reducers: {
      setContactsFilterCursor: (state, action) => {
         state.cursor = action.payload
      },
      setContactsFilterSearchValue: (state, action) => {
         state.searchValue = action.payload
         state.cursor = ''
      },
      setContactsFilterChannel: (state, action) => {
         state.channel = action.payload
         state.cursor = ''
      },
      setContactsFilter: (state, action) => {
         return { 
            ...state,
            ...action.payload 
         }
      }
   }
})

export const {
   setContactsFilterCursor,
   setContactsFilterSearchValue,
   setContactsFilterChannel,
   setContactsFilter
} = contactsFilterSlice.actions
export default contactsFilterSlice.reducer