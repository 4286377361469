import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { copyImageToClipboard } from 'copy-image-clipboard'
import ReplyMessage from "./ReplyMessage";
import {isIOS, isMacOs} from "react-device-detect";
import OgvPlayer from "../OgvPlayer";
import {convertDate, REVOKE_MESSAGE_TEXT, REVOKE_STATUS} from "../utils/helper";
import {parseRef} from "../utils/ParseRef";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import ErrorDesc from "./ErrorDesc";
import MessageStatus from './MessageStatus'
import {checkCanRevokeMessage, getIsTouchDevice, getMailerName} from '../../utils'
import useAddMessageToUnreadOnVisibility from '../../hooks/useAddMessageToUnreadOnVisibility';
import { managerStatuses, messageStatuses } from '../../utils/consts'
import ImageWithoutTouch from '../ImageWithoutTouch';
import $axios from '../../http';

const Message = ({
   message,
   selectedMessages,
   index,
   isMessageOfPrevMailer,
   setImagePreviewLink,
   setImagePreviewModal,
   setImagePreviewType,
   setImagePreviewId,
   setImagePreviewThumb,
   setCommentReplySection,
   autoReadStatus,
   fetchDialogData,
   setUnreadMessagesIds,
   handleMessageStatusClick,
   handleReplyMessageClick,
   selectedRepliedMessage,
   setSelectedRepliedMessage,
   onEditMessageClick,
   onMessageSelect,
   onForwardClick
}) => {
   const [messageText, setMessageText] = useState(message.text || '')
   const [messageStatus, setMessageStatus] = useState(message.status)
   const [isMessageActive, setIsMessageActive] = useState(false)
   const [copiedText, setCopiedText] = useState('')

   const mailerName = getMailerName(message)
   const managerStatus = message.manager?.status
   const showManagerStatus = managerStatus === managerStatuses.inactive
   const canRevokeMessage = checkCanRevokeMessage(message)
   const imgSrc = message.link || message.thumb || message?.params?.media_photo
   const isTouchDevice = getIsTouchDevice()
   const holdToDisplayContextMenu = isTouchDevice ? 500 : -1
   const isMessageSelected = selectedMessages.some(selectedMessage => selectedMessage.id === message.id)

   const handleMessageVisibility = (messageExternalId) => {
      setUnreadMessagesIds(ids => [...ids, messageExternalId])
   }

   const [messageRef] = useAddMessageToUnreadOnVisibility(autoReadStatus, message, handleMessageVisibility)

   const handleContextMenuShow = () => {
      const selectedText = window.getSelection().toString();
      setCopiedText(selectedText)
   }

   const handleCopyText = () => {
      navigator.clipboard.writeText(copiedText || message.text)
   }

   const handleCopyImage = async () => {
      try {
         await copyImageToClipboard(imgSrc)
      } catch (err) {
         alert('Не удалось скопировать изображение')
      }
   }

   const ogvFormatCheck = (link) => {
      let separatedString = link.split('.');
      let extension = separatedString[separatedString.length -1];
      let formats = ['oga', 'ogg', 'ogv'];

      return formats.includes(extension);
   }

   const handleReplyClick = (e, { message }) => {
      setCommentReplySection(message)
   }

   const getMessageText = (message) => {
      if (message.status === REVOKE_STATUS) {
         return parseRef(REVOKE_MESSAGE_TEXT)
      }
      if (message.type !== 'text') {
         if (message.link) {
            if (message.type === 'story') {
               return message.text &&
                   message.text !== message.link &&
                   messageText.split(':')[0]
            } else {
               return message.text &&
               message.text !== message.link
                   ? messageText
                   : ''
            }
         } else if (message.comment_id){
            return message.text &&
            message.text !== message.params.media_link
                ? messageText
                : ''
         }
      }
      return message.text &&
         message.text !== message.link
             ? parseRef(messageText)
             : ''
   }

   const onHandleRightClick = (e, data) => {
      $axios
          .post(`message/revoke`, {
             messageId: data.message.id,
             dialogId: data.message.dialog_id,
          })
          .then((response) => {
             if (response.data.error === true) {
                alert('Произошла ошибка!')
                return
             }
             setMessageStatus(REVOKE_STATUS)
             setMessageText(REVOKE_MESSAGE_TEXT)
          })
          .catch((err) => {
             console.log(err)
             alert('Произошла ошибка!')
          })
   }

   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/chat/clip.svg'
   }

   const handleToggleIsMessageActive = () => setIsMessageActive(active => !active)

   const handleEditMessageClick = () => {
      onEditMessageClick(message)
   }

   const handleMessageSelect = (e) => {
      e?.stopPropagation()
      onMessageSelect(isMessageSelected, message)      
   }

   const handleMessageClick = () => {
      if (selectedMessages.length < 100) {
         selectedMessages.length && handleMessageSelect()
      } else {
         alert('Максимальное количество сообщений для выделения 100!')
      }
   }

   const handleForwardClick = (e) => {
      e.stopPropagation()
      onForwardClick(message)        
   }

   useEffect(() => {
      setMessageStatus(message.status)
   },[message.status])

   useEffect(() => {
      setMessageText(message.text || '')
   },[message.text])

   useEffect(() => {
      if (selectedRepliedMessage === message.id && messageRef.current) {
         messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
         handleToggleIsMessageActive()
         setTimeout(() => {
            handleToggleIsMessageActive()
            setSelectedRepliedMessage(null)
         }, 2500)
      }
   }, [selectedRepliedMessage])

   return (
       <div
           ref={messageRef}
           className={`
              ${message.is_incoming ? 'mainbar-conversation-input-message-card' : 'mainbar-conversation-output-message-card'} 
              ${isMessageActive || isMessageSelected ? 'mainbar-conversation-message-card_active' : ''}
              ${selectedMessages.length ? 'mainbar-conversation-message-card_disabled' : ''}
           `}
           key={index}
           onClick={handleMessageClick}
       >
          {!isMessageOfPrevMailer && (
            <p className='mainbar-conversation-message-mailer-name'>
               {mailerName}
               <span className={
                  `mainbar-conversation-message-mailer-name__status ${
                  showManagerStatus 
                     ? 'mainbar-conversation-message-mailer-name__status_show' 
                     : ''
               }`}>
                  {' (неактивный)'}
               </span>
            </p>
          )}
          <ContextMenuTrigger 
            id={String(message.id)} 
            attributes={{className: 'mainbar-conversation-message-card-wrapper'}} 
            holdToDisplay={holdToDisplayContextMenu}
          >
             {message.reply_message && (
                <ReplyMessage
                   message={message.reply_message}
                   onClick={handleReplyMessageClick}
                />
             )}
             <p className="mainbar-conversation-message-card-text">
                {messageStatus === REVOKE_STATUS ? (
                    getMessageText(message)
                ) : (
                    <>
                       {message.type !== 'text' ? (
                           message.link ? (
                               message.type === 'image' ? (
                                   <>
                                      <ImageWithoutTouch
                                          className='mainbar-conversation-message-card__image'
                                          src={
                                             message.type === 'image'
                                                 ? message.link
                                                 : `/assets/chat/clip.svg`
                                          }
                                          isTouchDevice={isTouchDevice}
                                          pressToAvoidAction={holdToDisplayContextMenu}
                                          onClick={() => {
                                             setImagePreviewLink(message.link)
                                             setImagePreviewModal(true)
                                             setImagePreviewType(message.type)
                                             setImagePreviewId(message.id)
                                             setImagePreviewThumb(message.thumb)
                                          }}
                                          onError={handleImageLoadingError}
                                      />
                                      <p
                                          style={{
                                             color: 'inherit',
                                             fontSize: 'inherit',
                                             fontFamily: 'inherit',
                                          }}
                                      >
                                         {getMessageText(message)}
                                      </p>
                                   </>
                               ) : message.type === 'video' ? (
                                   <div
                                       style={{
                                          position: 'relative',
                                          width: '150px',
                                          height: '150px'
                                       }}
                                       onClick={() => {
                                          setImagePreviewLink(message.link)
                                          setImagePreviewModal(true)
                                          setImagePreviewType(message.type)
                                          setImagePreviewId(message.id)
                                          setImagePreviewThumb(message.thumb)
                                       }}
                                   >
                                      <img
                                          src="/assets/chat/play.svg"
                                          alt=""
                                          style={{
                                             width: '50px',
                                             height: '50px',
                                             position: 'absolute',
                                             top: '50px',
                                             left: '50px',
                                             opacity: '0.7',
                                             cursor: 'pointer',
                                             zIndex: '1'
                                          }}
                                      />
                                      <ImageWithoutTouch
                                          className='mainbar-conversation-message-card__video'
                                          src={
                                             message.type === 'video'
                                                 ? message.thumb
                                                 : `/assets/chat/clip.svg`
                                          }
                                          isTouchDevice={isTouchDevice}
                                          pressToAvoidAction={holdToDisplayContextMenu}
                                          onError={handleImageLoadingError}
                                      />
                                      <p
                                          style={{
                                             color: 'inherit',
                                             fontSize: 'inherit',
                                             fontFamily: 'inherit',
                                          }}
                                      >
                                         {getMessageText(message)}
                                      </p>
                                   </div>
                               ) : message.type === 'media' ? (
                                   <>
                                      <a
                                          href={message.link}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                             color: 'inherit',
                                             display: 'block',
                                          }}
                                      >
                                         {message.link}
                                      </a>
                                      <ImageWithoutTouch
                                          className='mainbar-conversation-message-card__media'
                                          src={
                                             message.type === 'media'
                                             ? message.thumb
                                             : `/assets/chat/clip.svg`
                                          }
                                          isTouchDevice={isTouchDevice}
                                          pressToAvoidAction={holdToDisplayContextMenu}
                                          onClick={() => {
                                             setImagePreviewLink(message.thumb)
                                             setImagePreviewModal(true)
                                             setImagePreviewType(message.type)
                                             setImagePreviewId(message.id)
                                             setImagePreviewThumb(message.thumb)
                                          }}
                                          onError={handleImageLoadingError}
                                      />
                                      <p
                                          style={{
                                             color: 'inherit',
                                             fontSize: 'inherit',
                                             fontFamily: 'inherit',
                                          }}
                                      >
                                         {getMessageText(message)}
                                      </p>
                                   </>
                               ) : message.type === 'story' ? (
                                   <>
                                      <ImageWithoutTouch
                                          className='mainbar-conversation-message-card__story'
                                          src={
                                             message.type === 'story'
                                                 ? message.thumb
                                                 : `/assets/chat/clip.svg`
                                          }
                                          isTouchDevice={isTouchDevice}
                                          pressToAvoidAction={holdToDisplayContextMenu}
                                          onClick={() => {
                                             setImagePreviewLink(message.thumb)
                                             setImagePreviewModal(true)
                                             setImagePreviewType('media')
                                             setImagePreviewId(message.id)
                                             setImagePreviewThumb(message.thumb)
                                          }}
                                          onError={handleImageLoadingError}
                                      />
                                       <p
                                           style={{
                                              color: 'inherit',
                                              fontSize: 'inherit',
                                              fontFamily: 'inherit',
                                           }}
                                       >
                                          Комментарий к истории:
                                       </p>
                                      <a
                                          href={message.link}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                             color: 'inherit',
                                             display: 'block',
                                          }}
                                      >
                                         {message.link}
                                      </a>
                                      <p
                                          style={{
                                             color: 'inherit',
                                             fontSize: 'inherit',
                                             fontFamily: 'inherit',
                                          }}
                                      >
                                          {getMessageText(message)}
                                      </p>
                                   </>
                               ) : message.type === 'audio' ? (
                                   <>
                                      {
                                         ((isMacOs || isIOS) && ogvFormatCheck(message.link)) ?
                                             <OgvPlayer
                                                 src={message.link}
                                             /> :
                                             <audio
                                                 controls
                                                 src={message.link}
                                                 className="audio_player"
                                             ></audio>
                                      }
                                   </>
                               ) : (
                                   message.type === 'document' && (
                                       <a
                                           href={message.link}
                                           download
                                           style={{
                                              color: 'inherit',
                                              display: 'flex',
                                           }}
                                       >
                                          <ImageWithoutTouch
                                             className='mainbar-conversation-message-card__document'
                                             src="/assets/chat/clip.svg"
                                             isTouchDevice={isTouchDevice}
                                             pressToAvoidAction={holdToDisplayContextMenu}
                                          />
                                          {getMessageText(message)}
                                       </a>
                                   )
                               )
                           ) : (
                               <span>Файл загружается...</span>
                           )
                       ) : message.comment_id ? (
                           <>
                              <p
                                 style={{
                                    color: 'inherit',
                                    fontSize: 'inherit',
                                    fontFamily: 'inherit',
                                 }}
                              >
                                 Комментарий к посту
                              </p>
                              <a
                                 href={message.params.media_link}
                                 target="_blank"
                                 rel="noreferrer"
                                 style={{
                                    color: 'inherit',
                                    display: 'block',
                                 }}
                              >
                                 {`${message.params.media_link}: `}
                              </a>
                              <p
                                 style={{
                                    color: 'inherit',
                                    fontSize: 'inherit',
                                    fontFamily: 'inherit',
                                 }}
                              >
                                 {getMessageText(message)}
                              </p>
                              <ImageWithoutTouch
                                 className='mainbar-conversation-message-card__post'
                                 src={message?.params?.media_photo || '/assets/chat/clip.svg'}
                                 isTouchDevice={isTouchDevice}
                                 alt="Пост"
                                 pressToAvoidAction={holdToDisplayContextMenu}
                                 onError={handleImageLoadingError}
                              />
                           </>
                       ) : (
                           <>
                              {message.link ? (
                                  <a
                                      href={message.link}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                         color: 'inherit',
                                         display: 'block',
                                      }}
                                  >
                                     {message.link}
                                  </a>
                              ) : (
                                  <></>
                              )}
                              {message.thumb && (
                                  <ImageWithoutTouch
                                    className='mainbar-conversation-message-card__image'
                                    src={message.thumb}
                                    pressToAvoidAction={holdToDisplayContextMenu}
                                    isTouchDevice={isTouchDevice}
                                    onClick={() => {
                                       setImagePreviewLink(message.thumb)
                                       setImagePreviewModal(true)
                                       setImagePreviewType('image')
                                       setImagePreviewId(message.id)
                                       setImagePreviewThumb(message.thumb)
                                    }}
                                    onError={handleImageLoadingError}
                                  />
                              )}
                              <div>
                                 {getMessageText(message)}
                              </div>
                           </>
                       )}
                    </>
                )}
             </p>
             <span className="mainbar-conversation-message-card-time">
                {convertDate(message.timestamp, true)}{' '}
                {message.is_incoming ? <MessageStatus
                    message={message}
                    messageStatus={messageStatus}
                    onClick={handleMessageStatusClick}
                /> : <ErrorDesc message={message} messageStatus={messageStatus}/>}
             </span>
          </ContextMenuTrigger>
          {message.status !== messageStatuses.deleted && (
            <ContextMenu 
               id={String(message.id)} 
               className="delete-message-pop-up-wrap" 
               onShow={handleContextMenuShow}
            >
               <MenuItem data={{message: message}} onClick={handleReplyClick} className="delete-message-pop-up">
                  <img
                     src={`/assets/chat/reply.svg`}
                     alt=""
                     className="delete-message-pop-up-icon"
                  />
                  Ответить
               </MenuItem>
               {Boolean(message.self_send) && message?.author?.type === 'whatsapp' && (
                  <MenuItem data={{message: message}} onClick={handleEditMessageClick} className="delete-message-pop-up">
                     <img
                        src={`/assets/chat/edit.svg`}
                        alt=""
                        className="delete-message-pop-up-icon"
                     />
                     Изменить
                  </MenuItem>
               )}
               <MenuItem 
                  className="delete-message-pop-up" 
                  onClick={handleCopyText}
               >
                  <img 
                     className="delete-message-pop-up-icon"
                     src="/assets/chat/clone.svg" 
                     alt="clone" 
                  />
                  Копировать текст
               </MenuItem>
               {imgSrc && (
                  <>
                     <MenuItem 
                        className="delete-message-pop-up"
                     >
                        <a href={imgSrc} download={'123'}>
                           <img
                              src={`/assets/chat/save-as.svg`}
                              alt=""
                              className="delete-message-pop-up-icon"
                           />
                           Сохранить изображение
                        </a>
                     </MenuItem>
                     <MenuItem 
                        className="delete-message-pop-up" 
                        onClick={handleCopyImage}
                     >
                        <img
                           src={`/assets/chat/copy-image.svg`}
                           alt=""
                           className="delete-message-pop-up-icon"
                        />
                        Копировать изображение
                     </MenuItem>
                     <MenuItem 
                        className="delete-message-pop-up" 
                     >
                        <a href={imgSrc} rel='noreferrer' target='_blank'>
                           <img
                              src={`/assets/chat/open-in-new-tab.svg`}
                              alt=""
                              className="delete-message-pop-up-icon"
                           />
                           Открыть изображение в новой вкладке
                        </a>
                     </MenuItem>
                  </>
               )}
               {canRevokeMessage && (
                  <MenuItem data={{message: message}} onClick={onHandleRightClick} className="delete-message-pop-up">
                     <img
                        src={`/assets/chat/delete.svg`}
                        alt=""
                        className="delete-message-pop-up-icon"
                     />
                     Удалить
                  </MenuItem>
               )}
               <MenuItem data={{message: message}} onClick={handleMessageSelect} className="delete-message-pop-up">
                  <img
                     src={`/assets/chat/select-message.svg`}
                     alt=""
                     className="delete-message-pop-up-icon"
                  />
                  {isMessageSelected ? 'Снять выделение' : 'Выделить'}
               </MenuItem>
               <MenuItem data={{message: message}} onClick={handleForwardClick} className="delete-message-pop-up">
                  <img
                     src={`/assets/chat/forward-message.svg`}
                     alt=""
                     className="delete-message-pop-up-icon"
                  />
                  Переслать
               </MenuItem>
            </ContextMenu>
          )}
       </div>
   )
}

export default Message
