import uniqid from 'uniqid'

import { managerAccessNotificationTypes } from './consts'

const createManagerAccessNotification = (data, type = managerAccessNotificationTypes.WARNING) => {
   return {
      id: uniqid(),
      type,
      data
   }
}

export default createManagerAccessNotification