export const btnThemes = {
   PRIMARY: 'primary',
   PRIMARY_SKINNY: 'primary_skinny',
   DANGER: 'danger',
   DANGER_SKINNY: 'danger_skinny',
   TEXT: 'text',
   LINK: 'link'
}

export const btnSizes = {
   DEFAULT: 'default',
   SMALL: 'small'
}

export const btnIcon = {
   NONE: 'none',
   LEFT: 'left',
   RIGHT: 'right'
}

export const btnThemeStyles = {
   primary: 'btn_primary',
   primary_skinny: 'btn_primary-skinny',
   danger: 'btn_danger',
   danger_skinny: 'btn_danger-skinny',
   text: 'btn_text',
   link: 'btn_link'
}

export const btnSizeStyles = {
   default: 'btn_default',
   small: 'btn_small'
}

export const btnIconStyles = {
   none: 'btn_icon-none',
   left: 'btn_icon-left',
   right: 'btn_icon-right'
}