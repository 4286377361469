import './warningModal.css'
import { CSSTransition } from 'react-transition-group'
import { Button } from '../Button'

const WarningModal = ({ isVisible, title, text, yes, no, disableYes }) => {
   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <div className="warning_wrapper">
               <span className="warning_title">{title}</span>
               <p className="warning_text">{text}</p>
               <div className="warning_buttons">
                  <Button
                     text='Да'
                     onClick={yes}
                     disabled={disableYes}
                  />
                  <Button
                     text='Нет'
                     onClick={no}
                  />
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default WarningModal
