import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   oAuthLink: '',
   isAuth: false,
   user: null,
   isManager: false
}

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      checkAuthSuccess: (state, action) => {
         state.isAuth = true
         state.user = action.payload
         state.isManager = Boolean(action.payload.user_id)
      },
      checkAuthFail: (state, action) => {
         state.oAuthLink = action.payload
      },
      managerLoginSuccess: (state) => {
         state.isManager = true
      },
      logoutSuccess: (state) => {
         state.oAuthLink = ''
         state.isAuth = false
         state.user = null
         state.isManager = false
      },
      setIsAuth: (state, action) => {
         state.isAuth = action.payload
      },
      setAutoReadStatus: (state, action) => {
         state.user = {
            ...state.user,
            dialog_auto_read: action.payload
         }
      },
      updateUserOnManagerAccessChange: (state, action) => {
         state.user = {
            ...state.user,
            ...action.payload
         }
      },
      updateFollowUpDateEnabledSuccess: (state, action) => {
         state.user = {
            ...state.user,
            follow_up_date_enabled: action.payload
         }
      },
      updateUserOnAccountSettingChange: (state, action) => {
         state.user = {
            ...state.user,
            ...action.payload
         }
      }
   }
})

export const { 
   checkAuthSuccess, 
   checkAuthFail, 
   managerLoginSuccess, 
   logoutSuccess,
   setIsAuth, 
   setAutoReadStatus,
   updateUserOnManagerAccessChange,
   updateFollowUpDateEnabledSuccess,
   updateUserOnAccountSettingChange,
} = authSlice.actions
export default authSlice.reducer