import './templates.css'
import { useEffect, useState } from 'react'
import PaginationItem from './PaginationItem'
import AddTemplate from '../modals/AddTemplate'
import TemplateItem from './TemplateItem'
import ReturnBtn from '../mainbar/ReturnBtn'
import { MAX_TEMPLATES_AMOUNT, MAX_TEMPLATE_SUBSECTION_AMOUNT } from '../../utils/consts'
import { getCurrentPortionOfItems, getPageNumbers } from '../../utils'
import CreateTemplateSubsection from '../modals/CreateTemplateSubsection'
import TemplateSubsectionItem from './TemplateSubsectionItem'
import { Button } from '../Button'

const Templates = ({ 
   templates, 
   templateSubsections,
   handleCreateTemplate,
   handleUpdateTemplate,
   handleDeleteTemplate,
   handleCreateTemplateSubsection,
   handleUpdateTemplateSubsection,
   handleDeleteTemplateSubsection,
   isMainbarTablet, 
   handleReturnBtnClick
 }) => {
   const [isShowAddTemplateModal, setIsShowAddTemplateModal] = useState(false)
   const [showCreateTemplateSubdivisionModal, setShowCreateTemplateSubdivisionModal] = useState(false)

   const [currentTemplatesPage, setCurrentTemplatesPage] = useState(1)
   const [templatesPerPage, setTemplatesPerPage] = useState(3)

   const [currentTemplateSubsectionsPage, setCurrentTemplateSubsectionsPage] = useState(1)
   const [templateSubsectionsPerPage, setTemplateSubsectionsPerPage] = useState(3)

   const [templateEditModalData, setTemplateEditModalData] = useState(null)
   const [templateSubsectionEditModalData, setTemplateSubsectionEditModalData] = useState(null)

   const lastTemplateIndex = currentTemplatesPage * templatesPerPage
   const firstTemplateIndex = lastTemplateIndex - templatesPerPage
   const lastTemplateSubsectionsIndex = currentTemplateSubsectionsPage * templateSubsectionsPerPage
   const firstTemplateSubsectionsIndex = lastTemplateSubsectionsIndex - templateSubsectionsPerPage
   const currentTemplates = getCurrentPortionOfItems(templates, firstTemplateIndex, lastTemplateIndex)
   const currentTemplateSubsections = getCurrentPortionOfItems(
      templateSubsections, 
      firstTemplateSubsectionsIndex, 
      lastTemplateSubsectionsIndex
   )

   const templatesPageNumbers = getPageNumbers(templates, templatesPerPage)
   const templateSubsectionsPageNumbers = getPageNumbers(templateSubsections, templateSubsectionsPerPage)

   const toggleIsShowAddTemplateModal = () => {
      setIsShowAddTemplateModal(!isShowAddTemplateModal)
   }

   const handleCreateTemplateModalClose = () => {
      templateEditModalData && setTemplateEditModalData(null)
      toggleIsShowAddTemplateModal()
   }

   const handleCreateTemplateModalSubmit = async (data) => {
      return await data.id
         ? handleUpdateTemplate(data)
         : handleCreateTemplate(data)
   }

   const handleToggleTemplateEditModal = (template) => {
      setTemplateEditModalData(template)
   }

   const toggleCreateTemplateSubsectionModal = () => setShowCreateTemplateSubdivisionModal(show => !show)

   const handleCreateTemplateSubsectionModalClose = () => {
      templateSubsectionEditModalData && setTemplateSubsectionEditModalData(null)
      toggleCreateTemplateSubsectionModal()
   }

   const handleCreateTemplateSubsectionModalSubmit = async (data) => {
      return await data.id
         ? handleUpdateTemplateSubsection(data)
         : handleCreateTemplateSubsection(data)
   }

   const handleToggleTemplateSubsectonEditModal = (templateSubsection) => {
      setTemplateSubsectionEditModalData(templateSubsection)
      toggleCreateTemplateSubsectionModal()
   }
   
   useEffect(() => {
      if (currentTemplates.length < 1 && currentTemplatesPage > 1) {
         setCurrentTemplatesPage((prevState) => prevState - 1)
      }
   },[templates])

   useEffect(() => {
      if (currentTemplateSubsections.length < 1 && currentTemplateSubsectionsPage > 1) {
         setCurrentTemplateSubsectionsPage((prevState) => prevState - 1)
      }
   }, [templateSubsections])

   return (
      <div className="settings-template-wrapper">
         <ReturnBtn
            isMainbarTablet={isMainbarTablet}
            onClick={handleReturnBtnClick}
         />
         <AddTemplate
            isVisible={isShowAddTemplateModal}
            title={templateEditModalData ? 'Изменить шаблон' : 'Новый шаблон'}
            text={templateEditModalData ? 'Сохранить' : 'Создать шаблон'}
            templateEditModalData={templateEditModalData}
            accept={handleCreateTemplateModalSubmit}
            cancel={handleCreateTemplateModalClose}
            templateSubsections={templateSubsections}
         />
         <CreateTemplateSubsection
            isVisible={showCreateTemplateSubdivisionModal}
            cancel={handleCreateTemplateSubsectionModalClose}
            templateSubsectionEditModeData={templateSubsectionEditModalData}
            onSubmit={handleCreateTemplateSubsectionModalSubmit}
         />
         <div className="tags-status-settings_item">
            <div className="tags-status-settings_block">
               <h2 className="tags-status-settings_title">Шаблоны</h2>
               <div className="settings-template__actions">
                  <Button
                     text='+ Создать шаблон'
                     onClick={toggleIsShowAddTemplateModal}
                     disabled={templates.length >= MAX_TEMPLATES_AMOUNT}
                  />
                  <Button
                     text='+ Создать подраздел'
                     onClick={toggleCreateTemplateSubsectionModal}
                     disabled={templateSubsections.length >= MAX_TEMPLATE_SUBSECTION_AMOUNT}
                  />
               </div>
            </div>
            {currentTemplates.length === 0 && (
               <span className="tags-status-settings_subtitle">
                  У вас еще нет ни одного шаблона
               </span>
            )}
            <ul
               style={currentTemplates.length ? {} : { display: 'none' }}
               className="template-settings_list"
            >
               <li className="template-header">
                  <div className="template-header_block">
                     <div>
                        <span>Название</span>
                     </div>
                     <div>
                        <span>Подраздел</span>
                     </div>
                     <div>
                        <span>Текст шаблона</span>
                     </div>
                     <div>
                        <span>Вложение</span>
                     </div>
                  </div>
               </li>
               {currentTemplates.map((template) => {
                  const templateSubsection = templateSubsections.find(subsection => subsection.subsection_id === template.subsection_id)

                  return (
                     <TemplateItem
                        key={template.id}
                        template={template}
                        templateSubsection={templateSubsection}
                        onEdit={handleToggleTemplateEditModal}
                        onDelete={handleDeleteTemplate}
                        toggleIsShowAddTemplateModal={toggleIsShowAddTemplateModal}
                     />
                  )
               })}
            </ul>
            {templates.length > 3 && (
               <>
                  <PaginationItem
                     setCurrentPage={setCurrentTemplatesPage}
                     pageNumbers={templatesPageNumbers}
                     currentPage={currentTemplatesPage}
                  />
               </>
            )}
            <div className="template-subsections">
               <h2 className="tags-status-settings_title">Подразделы</h2>
               {!templateSubsections.length && (
                  <span className="tags-status-settings_subtitle">
                     У вас еще нет ни одного подраздела шаблонов
                  </span>
               )}
               <ul
                  style={templateSubsections.length > 0 ? {} : { display: 'none' }}
                  className="template-settings_list"
               >
                  <li className="template-header">
                     <div className="template-header_block">
                        <div>
                           <span>Название</span>
                        </div>
                     </div>
                  </li>
                  {currentTemplateSubsections.map((subsection) => (
                     <TemplateSubsectionItem
                        key={subsection.subsection_id}
                        subsection={subsection}
                        onEdit={handleToggleTemplateSubsectonEditModal}
                        onDelete={handleDeleteTemplateSubsection}
                     />
                  ))}
               </ul>
               {templateSubsections.length > 3 && (
                  <>
                     <PaginationItem
                        setCurrentPage={setCurrentTemplateSubsectionsPage}
                        pageNumbers={templateSubsectionsPageNumbers}
                        currentPage={currentTemplateSubsectionsPage}
                     />
                  </>
               )}
            </div>
         </div>
      </div>
   )
}

export default Templates
