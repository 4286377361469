import { combineReducers, configureStore } from "@reduxjs/toolkit"
import auth from "./slices/auth"
import channels from "./slices/channels"
import dialogs from "./slices/dialogs"
import dialogsFilter from "./slices/dialogsFilter"
import dialogSteps from "./slices/dialogSteps"
import messages from "./slices/messages"
import messagesFilter from "./slices/messagesFilter"
import tags from "./slices/tags"
import templates from "./slices/templates"
import contacts from "./slices/contacts"
import contactsFilter from "./slices/contactsFilter"
import notifications from "./slices/notifications"

const rootReducer = combineReducers({
   auth,
   channels,
   dialogs,
   dialogsFilter,
   dialogSteps,
   messages,
   messagesFilter,
   tags,
   templates,
   contacts,
   contactsFilter,
   notifications
})

const store = configureStore({
   reducer: rootReducer
})

export default store