import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './ManagerAccessNotificator.css'

import { notifications_selectManagerAccessNotifications } from '../../store/slices/notifications/selectors'
import { deleteManagerAccessNotification } from '../../store/slices/notifications'

import ManagerAccessNotification from './ManagerAccessNotification'

const ManagerAccessNotificator = () => {
   const notifications = useSelector(notifications_selectManagerAccessNotifications)
   
   const dispatch = useDispatch()

   const handleCloseNotification = (id) => {
      dispatch(deleteManagerAccessNotification(id))
   }

   return (
      <div className='manager-access-notificator'>
         <ul className='manager-access-notificator__list'>
            {notifications.map(notification => (
               <li 
                  className='manager-access-notificator__list-item'
                  key={notification.id}
               >
                  <ManagerAccessNotification 
                     notification={notification}
                     onClose={handleCloseNotification}
                  />
               </li>
            ))}
         </ul>
      </div>
   )
}

export default ManagerAccessNotificator