import React, { useEffect, useState } from 'react'

import './ForwardMessagesSection.css'
import { useClickOutside } from '../../../hooks'
import { WHATSAPP } from '../../../utils/consts'
import { Button } from '../../Button'

const ForwardMessagesSection = ({ 
   messageToForward, 
   selectedDialog, 
   dialogs, 
   selectedMessages, 
   onForward, 
   onForwardCancel,
   onDelete, 
   onClear, 
   onScroll
}) => {
   const [showDialogsList, setShowDialogsList] = useState(messageToForward ? true : false)
   const [disableScroll, setDisableScroll] = useState(false)
   const [shouldClear, setShouldClear] = useState(false)

   const messagesToDelete = selectedMessages.filter(message => !message.is_incoming)   
   
   const handleForwardClick = () => setShowDialogsList(show => !show)
   
   const handleDialogsListClose = () => {
      setShowDialogsList(false)
      if (messageToForward) onForwardCancel()
   }

   const handleForward = (toDialogId) => {
      const messageIds = messageToForward 
         ? [messageToForward.id] 
         : selectedMessages.map(message => message.id)
      
      onForward({
         toDialogId,
         fromDialogId: selectedDialog.dialog_id,
         messageIds
      })
      onClear()
      handleDialogsListClose()
   }

   const handleDelete = () => {
      onDelete(messagesToDelete)
      onClear()
   }

   const handleClear = () => onClear()

   const handleDialogsScroll = async (e) => {
      if (
         e.target.scrollHeight - e.target.scrollTop <=
         e.target.clientHeight + 35 && !disableScroll 
      ) {         
         setDisableScroll(true)
         await onScroll()
         setDisableScroll(false)
      }
   }

   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/sidebar/default_avatar.png'
   }

   const dialogsListRef = useClickOutside(handleDialogsListClose)

   useEffect(() => setShowDialogsList(messageToForward ? true : false), [messageToForward])
   useEffect(() => {
      if (!shouldClear) return setShouldClear(true)
      
      if (selectedMessages.length) {         
         onClear()
      }
   }, [selectedDialog.dialog_id])

   return (
      <div className='forward-messages-section'>
         <div className="forward-messages-section__body">
            <div ref={dialogsListRef} className={`forward-messages-section__dialogs-container ${showDialogsList ? 'active' : ''}`}>
               {!messageToForward && (
                  <Button
                     text={`Переслать (${selectedMessages.length})`}
                     onClick={handleForwardClick}
                  />
               )}
               <div className='forward-messages-section__dialogs-list forward-messages-section-dialogs-list'>
                  <div className="forward-messages-section-dialogs-list__body">
                     <ul 
                        className='forward-messages-section-dialogs-list__list'
                        onScroll={handleDialogsScroll}
                     > 
                        {dialogs.map(dialog => (
                           <li 
                              key={dialog.dialog_id}
                              className='forward-messages-section-dialogs-list__list-item forward-messages-section-dialog-card'
                              onClick={() => handleForward(dialog.dialog_id)}
                           >
                              <div className='forward-messages-section-dialog-card__avatar'>
                                 <img 
                                    className='forward-messages-section-dialog-card__avatar-photo' 
                                    src={
                                       dialog.dialog_picture
                                          ? dialog.dialog_picture
                                          : `/assets/sidebar/default_avatar.png`
                                    } 
                                    alt="Avatar"
                                    onError={handleImageLoadingError}
                                 />
                                 <img 
                                    className='forward-messages-section-dialog-card__channel-icon' 
                                    src={`/assets/sidebar/${dialog.dialog_domain}-icon.svg`} 
                                    alt="" 
                                 />
                              </div>
                              <div className='forward-messages-section-dialog-card__client-name'>
                                 {dialog.client.name}
                              </div>
                           </li>
                        ))}
                     </ul>
                  </div>
               </div>
            </div>
            {!messageToForward && (
               <>
                  {selectedDialog.dialog_channel === WHATSAPP && messagesToDelete.length > 0 && (
                     <button
                        type='button'
                        className='forward-messages-section__btn forward-messages-section__btn_delete'
                        onClick={handleDelete}
                     >
                        {`Удалить (${messagesToDelete.length})`}
                     </button>
                  )}
                  <button
                     type='button'
                     className='forward-messages-section__clear-btn'
                     onClick={handleClear}
                  >
                     <img src="/assets/chat/clear-selection.svg" alt="" />
                  </button>
               </>
            )}
         </div>
      </div>
   )
}

export default ForwardMessagesSection