import React, { useState } from 'react'

import './Select.css'

import { useClickOutside } from '../../hooks'

const Select = ({ 
   items, 
   selectedItems, 
   placeholderText = '', 
   customStyle = '', 
   onSelect, 
   onDelete 
}) => {
   const [isActive, setIsActive] = useState(false)
   
   const handleToggleIsActive = () => setIsActive(isActive => !isActive)

   const handleCloseSelect = () => setIsActive(false)

   const handleSelect = (item, isSelected) => {
      onSelect(item, isSelected)
   }

   const handleDelete = (e, id) => {
      e.stopPropagation()
      onDelete(id)
   }
   
   const ref = useClickOutside(handleCloseSelect)
   
   return (
      <div ref={ref} className={`select ${customStyle} ${isActive ? 'active' : ''}`}>
         <button
            className='select__btn'
            onClick={handleToggleIsActive}
         >
            <div className='select__value'>
               {!selectedItems.length 
                  ? placeholderText
                  : (
                     <div className='select__value-list'>
                        {selectedItems.map(item => (
                           <div className='select__value-item' key={item.id}>
                              <span className='select__value-item-text'>
                                 {item.name}
                              </span>
                              {isActive && (
                                 <button 
                                    className='select__value-item-delete-btn'
                                    onClick={(e) => handleDelete(e, item.id)}
                                 >
                                    <img src='/assets/controls/close.svg' alt='' />
                                 </button>
                              )}
                           </div>
                        ))}
                     </div>
                  )}
               <img 
                  className='select__dropdown-icon'
                  src='/assets/sidebar/select-dropdown-icon.svg' alt='' 
               />
            </div>
         </button>
         {items.length > 0 && (
            <div className='select__list-wrapper'>
               <ul className='select__list'>
                  {items.map(item => {
                     const isSelected = selectedItems.some(selectedItem => selectedItem.id === item.id)
                     return (
                        <li
                           key={item.id}
                           className={`select__list-item ${isSelected ? 'select__list-item_selected' : ''}`}
                           onClick={() => handleSelect(item, isSelected)}
                        >
                           <div className='select__list-item-checkbox'>
                              <img src='/assets/sidebar/select-checkbox-icon.svg' alt='' />
                           </div>
                           <p className='select__list-item-text'>
                              {item.name}
                           </p>
                        </li>
                     )
                  })}
               </ul>
            </div>
         )}
      </div>
   )
}

export default Select