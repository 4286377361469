import React, { useState } from 'react'
import SidebarList from './SidebarList'
import './sidebar.css'
import ManagerSidebar from '../manager/ManagerSidebar'
import { useSelector } from 'react-redux'
import { auth_selectIsManager, auth_selectUser } from '../../store/slices/auth/selectors'
import { Button, btnThemes } from '../Button'

export const SidebarHeader = ({
   setSelectedChannel,
   channelList,
   sidebarToggle,
   userName,
}) => {
   const user = useSelector(auth_selectUser)
   const isManager = useSelector(auth_selectIsManager)
   const isContactListUnloadAllowed = isManager ? user?.unload_contact_list : true

   let instagramChannels =
      channelList &&
      channelList.filter((channel) => channel.type === 'instagram')
   let whatsappChannels =
      channelList &&
      channelList.filter((channel) => channel.type === 'whatsapp')
   instagramChannels = instagramChannels
      ? instagramChannels.map((channel) => ({
           value: `${channel.username}`,
           label: `instagram - ${channel.username}`,
        }))
      : []

   whatsappChannels = whatsappChannels
      ? whatsappChannels.map((channel) => ({
           value: `${channel.username}`,
           label: `whatsapp - ${channel.username}`,
        }))
      : []

   const options = [
      { value: 'all', label: 'Все каналы' },
      { value: 'instagram', label: '- Instagram каналы -' },
      ...instagramChannels,
      { value: 'whatsapp', label: '- Whatsapp каналы -' },
      ...whatsappChannels,
   ]

   const recognizeTitle = () => {
      switch (sidebarToggle) {
         case 1:
            return 'Диалоги'
         case 2:
            return 'Контакты'
         case 3:
            return 'Менеджеры'
         case 4:
            return 'Настройки'
         default:
            return userName
      }
   }

   return (
      <div className="sidebar-header">
         <div className="sidebar-header-wrapper">
            <div className="sidebar-header-profile">
               <div className="sidebar-header-profile-pic">
                  <div className="sidebar-header-profile-display">
                     <span className="sidebar-header-profile-name">
                        {recognizeTitle()}
                     </span>
                     {sidebarToggle === 2 && isContactListUnloadAllowed && (
                        <Button
                           customStyle='sidebar-header-load-contacts-btn'
                           theme={btnThemes.LINK}
                        >
                           <svg
                              width="16"
                              height="16"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 fill-rule="evenodd"
                                 clip-rule="evenodd"
                                 d="M19 9.25C19.4142 9.25 19.75 9.58579 19.75 10V19C19.75 19.4142 19.4142 19.75 19 19.75H1C0.585786 19.75 0.25 19.4142 0.25 19V10.0042C0.25 9.58994 0.585786 9.25415 1 9.25415C1.41421 9.25415 1.75 9.58994 1.75 10.0042V18.25H18.25V10C18.25 9.58579 18.5858 9.25 19 9.25Z"
                              />
                              <path
                                 fill-rule="evenodd"
                                 clip-rule="evenodd"
                                 d="M9.46967 0.46967C9.76256 0.176777 10.2374 0.176777 10.5303 0.46967L15.0303 4.96967C15.3232 5.26256 15.3232 5.73744 15.0303 6.03033C14.7374 6.32322 14.2626 6.32322 13.9697 6.03033L10 2.06066L6.03033 6.03033C5.73744 6.32322 5.26256 6.32322 4.96967 6.03033C4.67678 5.73744 4.67678 5.26256 4.96967 4.96967L9.46967 0.46967Z"
                              />
                              <path
                                 fill-rule="evenodd"
                                 clip-rule="evenodd"
                                 d="M9.99585 0.25C10.4101 0.25 10.7458 0.585786 10.7458 1V14C10.7458 14.4142 10.4101 14.75 9.99585 14.75C9.58164 14.75 9.24585 14.4142 9.24585 14V1C9.24585 0.585786 9.58164 0.25 9.99585 0.25Z"
                              />
                           </svg>
                           <a
                              className="load-contacts-ref"
                              href="/api/v1/contact/unload"
                              target="_blank"
                           >
                              Выгрузить контакты
                           </a>
                        </Button>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export const Sidebar = ({
   handleDeleteAttachedTagFromDialog,
   dialogsLoading,
   searchValue,
   isUnreadOnlyChecked,
   handleSearchChange,
   handleUnreadOnlyChange,
   handleDateStatusChange,
   selectedDateStatus,
   handleSetSelectedDialog,
   selectedConversation,
   allowedChannel,
   allowedTag,
   allowedStatus,
   isManager,
   dialogs,
   setDialogs,
   userName,
   sidebarToggle,
   setAddManagerPage,
   setManagerCardClick,
   handleDialogsScroll,
   newContactInitiationParams,
   showAddContactModal,
   setShowAddContactModal,
   autoReadStatus,
   setAutoReadStatus,
   setSettingsToggle,
   channelList,
   toggleSidebarView,
   setToggleSidebarView,
   width,
   handleContactCardClick,
   managerSidebarLoader,
   managers,
   managerList,
   setManagerList,
   followUpDateEnabled
}) => {
   const [selectedChannel, setSelectedChannel] = useState('all')

   const toggleSidebarIconActive = {
      height: '15px',
      width: '15px',
      filter: 'brightness(0) invert(1)',
   }

   const toggleSidebarIconInactive = {
      height: '15px',
      width: '15px',
   }

   return sidebarToggle === 3 ? (
      <div
         className={toggleSidebarView ? 'sidebar-active' : 'sidebar-inactive'}
      >
         <div className="sidebar-wrapper">
            <ManagerSidebar
               setAddManagerPage={setAddManagerPage}
               setManagerCardClick={setManagerCardClick}
               setSelectedChannel={setSelectedChannel}
               channelList={channelList}
               userName={userName}
               managerSidebarLoader={managerSidebarLoader}
               managers={managers}
               managerList={managerList}
               setManagerList={setManagerList}
            />
         </div>
      </div>
   ) : (
      <div
         className={toggleSidebarView ? 'sidebar-active' : 'sidebar-inactive'}
      >
         <div
            className="sidebar-wrapper"
            style={toggleSidebarView ? {} : { display: 'none' }}
         >
            <SidebarHeader
               setSelectedChannel={setSelectedChannel}
               userName={userName}
               channelList={channelList}
               sidebarToggle={sidebarToggle}
               autoReadStatus={autoReadStatus}
               setAutoReadStatus={setAutoReadStatus}
            />
         </div>
         <SidebarList
            handleDeleteAttachedTagFromDialog={handleDeleteAttachedTagFromDialog}
            dialogsLoading={dialogsLoading}
            searchValue={searchValue}
            isUnreadOnlyChecked={isUnreadOnlyChecked}
            handleSearchChange={handleSearchChange}
            handleUnreadOnlyChange={handleUnreadOnlyChange}
            handleDateStatusChange={handleDateStatusChange}
            selectedDateStatus={selectedDateStatus}
            handleSetSelectedDialog={handleSetSelectedDialog}
            selectedConversation={selectedConversation}
            allowedChannel={allowedChannel}
            allowedTag={allowedTag}
            allowedStatus={allowedStatus}
            isManager={isManager}
            selectedChannel={selectedChannel}
            sidebarToggle={sidebarToggle}
            dialogs={dialogs}
            setDialogs={setDialogs}
            channelList={channelList}
            onDialogsScroll={handleDialogsScroll}
            newContactInitiationParams={newContactInitiationParams}
            showAddContactModal={showAddContactModal}
            setShowAddContactModal={setShowAddContactModal}
            setSettingsToggle={setSettingsToggle}
            toggleSidebarView={toggleSidebarView}
            handleContactCardClick={handleContactCardClick}
            followUpDateEnabled={followUpDateEnabled}
         />
      </div>
   )
}
