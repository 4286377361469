export const messengerLimits = {
   whatsapp: {
      textLimit: 64000,
      documents: {
         formats: ['application/pdf', 'application/docx', 'application/xlsx'],
         maxSize: 100,
      },
      audio: {
         formats: [
            'audio/mp4',
            'audio/mp3',
            'audio/mpeg',
            'audio/m4a',
            'audio/ogg',
            'audio/aac',
            'audio/amr',
            'audio/mp3',
         ],
         maxSize: 64,
         minDuration: Infinity,
         maxDuration: Infinity,
      },
      video: {
         formats: ['video/mp4'],
         maxSize: 64,
         maxResolutions: { width: Infinity, height: Infinity },
         minDuration: Infinity,
         maxDuration: Infinity,
      },
      images: {
         formats: [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/gif',
            'image/webp',
            'image/bmp',
         ],
         maxSize: 64,
         minResolutions: { width: 1, height: 1 },
         maxResolutions: { width: 15360, height: 15360 },
      },
   },
   avito: {
      textLimit: 64000,
      documents: {
         formats: ['application/pdf', 'application/docx', 'application/xlsx'],
         maxSize: 100,
      },
      audio: {
         formats: [
            'audio/mp4',
            'audio/mp3',
            'audio/mpeg',
            'audio/m4a',
            'audio/ogg',
            'audio/aac',
            'audio/amr',
            'audio/mp3',
         ],
         maxSize: 64,
         minDuration: Infinity,
         maxDuration: Infinity,
      },
      video: {
         formats: ['video/mp4'],
         maxSize: 64,
         maxResolutions: { width: Infinity, height: Infinity },
         minDuration: Infinity,
         maxDuration: Infinity,
      },
      images: {
         formats: [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/gif',
            'image/webp',
            'image/bmp',
         ],
         maxSize: 64,
         minResolutions: { width: 1, height: 1 },
         maxResolutions: { width: 15360, height: 15360 },
      },
   },
   instagram: {
      textLimit: 1000,
      documents: {
         formats: ['empty'],
         maxSize: Infinity,
      },
      audio: {
         formats: ['audio/mp4', 'audio/m4a', 'audio/mpeg'],
         maxSize: Infinity,
         minDuration: 1,
         maxDuration: 60,
      },
      video: {
         formats: ['video/mp4'],
         maxSize: 30,
         maxResolutions: { width: 1920, height: 1080 },
         maxDuration: 90,
      },
      images: {
         formats: [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/gif',
            'image/webp',
            'image/bmp',
         ],
         maxSize: 30,
         minResolutions: { width: 1, height: 1 },
         maxResolutions: { width: 7680, height: 7680 },
      },
   },
   vkontakte: {
      textLimit: 4096,
      documents: {
         formats: ['application/pdf', 'application/docx', 'application/xlsx'],
         maxSize: 100,
      },
      audio: {
         formats: ['audio/mp4', 'audio/m4a'],
         maxSize: Infinity,
         minDuration: 1,
         maxDuration: 60,
      },
      video: {
         formats: [
            'video/avi',
            'video/mp4',
            'video/3gp',
            'video/mpeg',
            'video/mov',
            'video/flv',
            'video/wmv',
         ],
         maxSize: Infinity,
         maxResolutions: { width: Infinity, height: Infinity },
         maxDuration: Infinity,
      },
      images: {
         formats: ['image/jpg', 'image/png', 'image/gif'],
         maxSize: 50,
         minResolutions: { width: 1, height: 1 },
         maxResolutions: { width: 14000, height: 14000 },
      },
   },
   telegram: {
      textLimit: 4096,
      documents: {
         formats: ['application/pdf', 'application/docx', 'application/xlsx'],
         maxSize: 100,
      },
      audio: {
         formats: ['audio/mp4', 'audio/m4a', 'audio/mpeg'],
         maxSize: Infinity,
         minDuration: 1,
         maxDuration: 60,
      },
      video: {
         formats: [
            'video/avi',
            'video/mp4',
            'video/3gp',
            'video/mpeg',
            'video/mov',
            'video/flv',
            'video/wmv',
         ],
         maxSize: Infinity,
         maxResolutions: { width: Infinity, height: Infinity },
         maxDuration: Infinity,
      },
      images: {
         formats: ['image/jpg', 'image/png', 'image/gif'],
         maxSize: 50,
         minResolutions: { width: 1, height: 1 },
         maxResolutions: { width: 14000, height: 14000 },
      },
   },
}
